import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import Slider from '@components/slider/Index';
import NextButton from '@components/slider/NextButton';
import PrevButton from '@components/slider/PrevButton';

import { GuideSectionType, SimpleHeadingType } from '../../types';
import NumberFeatureCard from '@components/creation-section/NumberFeatureCard';

interface GuideSectionProps {
  platform: string;
  heading: SimpleHeadingType;
  cards: GuideSectionType[];
  isLayoutV3?: boolean;
}

function GuideSection(props: GuideSectionProps) {
  const { platform, heading, cards, isLayoutV3 } = props;

  return (
    <section
      id="iv-guide"
      className={ivclass(
        'xl:iv-pt-200 iv-pt-150 sm:iv-pt-100 iv-overflow-x-hidden',
      )}
    >
      <div className={ivclass('iv-container iv-mx-auto')}>
        <div
          className={ivclass(
            'xl:iv-pb-75 iv-pb-50 sm:iv-pb-30',
            'iv-pl-60 lg:iv-pl-75',
            isLayoutV3 ? 'sm:iv-px-20' : 'sm:iv-pl-[45px]',
          )}
        >
          <h2
            className={ivclass(
              'iv-text-grey-100',
              'xl:iv-text-heading-4xl iv-text-heading-2xl sm:iv-text-heading-2xs',
              'xl:iv-m-heading-4xl iv-m-heading-2xl sm:iv-m-heading-xs',
              'iv-font-extrabold',
              isLayoutV3 && 'iv-text-center',
            )}
          >
            {heading.plainHeading}
            <span className={ivclass('iv-title-gradient', 'sm:iv-block')}>
              {heading.highlightedHeading}
            </span>
          </h2>
        </div>
      </div>
      <Slider
        sliderWrapperClasses={
          'xl:iv-ml-[135px] lg:iv-ml-40 md:iv-ml-25 sm:iv-ml-20'
        }
        renderButtons={(
          scrollPrev,
          scrollNext,
          prevBtnEnabled,
          nextBtnEnabled,
        ) =>
          platform === 'mobile' || platform === 'tablet' ? (
            <React.Fragment />
          ) : (
            <React.Fragment>
              <PrevButton
                clickHandler={scrollPrev}
                isEndReached={prevBtnEnabled}
              />
              <NextButton
                clickHandler={scrollNext}
                isEndReached={nextBtnEnabled}
              />
            </React.Fragment>
          )
        }
      >
        {cards.map(({ detail, heading }, i) => (
          <div key={i} className="iv-relative">
            <NumberFeatureCard
              details={detail}
              heading={heading}
              number={i + 1}
              containerClass={ivclass(
                'xl:iv-w-[440px] lg:iv-w-[402px] md:iv-w-[350px] sm:iv-w-[300px]',
                'xl:iv-h-[522px] lg:iv-h-[446px] md:iv-h-[436px] sm:iv-h-[401px]',
                'iv-mr-40 md:iv-mr-25 sm:iv-mr-20',
                'iv-bg-black-4',
              )}
              highlightedTextClass="iv-text-black-85"
              numberClass={ivclass(
                'iv-text-heading-7xl sm:iv-text-heading-5xl',
                'iv-text-black-7',
              )}
              detailsWrapperClass={ivclass('iv-pr-10')}
              detailsClass={ivclass(
                'xl:iv-text-heading-4xs lg:iv-text-body-3xl md:iv-text-body-2xl sm:iv-text-body-2xl',
                'xl:iv-pt-15 lg:iv-pt-8 md:iv-pt-[11px] sm:iv-pt-1',
                'iv-text-black-50',
              )}
              headingClass={ivclass(
                'iv-pr-20',
                'iv-py-10',
                'iv-text-heading-xs lg:iv-text-heading-2xs md:iv-text-heading-3xs sm:iv-text-heading-4xs',
                'iv-m-heading-xs lg:iv-m-heading-2xs md:iv-m-heading-3xs sm:iv-m-heading-4xs',
              )}
              wrapperClass={ivclass(
                'iv-flex',
                'iv-flex-col iv-justify-between',
              )}
              contentContainerClass={ivclass(
                'xl:iv-pl-[12px] xl:iv-pt-2 lg:iv-pl-7 md:iv-pl-8 sm:iv-pl-4 sm:iv-pt-5',
              )}
            />
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default GuideSection;
